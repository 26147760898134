import React, { useEffect, useState } from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";

interface MarkdownRendererProps {
  text: string; // Markdown text as a string
}

const MarkdownRenderer: any = ({ text }: any) => {
  const [htmlContent, setHtmlContent] = useState<string>("");

  useEffect(() => {
    const processMarkdown = async () => {
      const rawHtml = await Promise.resolve(marked(text)); // Handle async or sync marked
      const sanitizedHtml = DOMPurify.sanitize(rawHtml); // Sanitize output
      setHtmlContent(sanitizedHtml);
    };

    processMarkdown();
  }, [text]);

  return (
    <div
      className="markdown-content"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export default MarkdownRenderer;
