import React from 'react'
import useData from '../../hooks/use'
import NightShelterIcon from '@mui/icons-material/NightShelter';
import { motion, useScroll, useSpring } from "framer-motion";
import { Button } from '@mui/material';
import './Shelter.css'
import Breaker from '../../components/Breaker/Breaker';


function Shelter() {

    const {state,actions} = useData()

    const visitText = {
        "English":"Visit Website",
        "Spanish":"VISITA EL SITIO WEB",
        "Italian":"VISITA IL SITO WEB",
        "Polish":"ODWIEDŹ STRONĘ"
    }

    const titleText = {
        "English":"Shelter",
        "Spanish":"Refugio",
        "Italian":"Riparo",
        "Polish":"Schronienie"
    }
    const subTitleText = {
        English:"Find a safe place to stay with our comprehensive directory of shelters in Hartford, CT. Whether you're in need of emergency housing, long-term support, or transitional living arrangements, our Shelter Services section provides up-to-date information on available options.",
        Spanish:"Encuentre un lugar seguro para quedarse con nuestro directorio completo de refugios en Hartford, CT. Ya sea que necesite vivienda de emergencia, apoyo a largo plazo o arreglos de vivienda de transición, nuestra sección de Servicios de refugio brinda información actualizada sobre las opciones disponibles.",
        Italian:"Trova un posto sicuro in cui soggiornare con il nostro elenco completo di rifugi a Hartford, CT. Che tu abbia bisogno di un alloggio di emergenza, di supporto a lungo termine o di soluzioni abitative transitorie, la nostra sezione Servizi di accoglienza fornisce informazioni aggiornate sulle opzioni disponibili.",
        Polish:"Znajdź bezpieczne miejsce na nocleg dzięki naszemu obszernemu katalogowi schronisk w Hartford w stanie Connecticut. Niezależnie od tego, czy potrzebujesz mieszkania w nagłych przypadkach, długoterminowego wsparcia czy przejściowych warunków życia, nasza sekcja Usług schronisk zapewnia aktualne informacje na temat dostępnych opcji."
    }

    const programs = [
        {
            title:{
                "English":"Mercy Housing and Shelter Corporation",
                "Spanish":"Corporación de Vivienda y Refugio de la Misericordia",
                "Italian":"Mercy Housing e Shelter Corporation",
                "Polish":"Spółka Mercy Housing and Shelter Corporation"
            },
            description:{
                "English":"Mercy Housing and Shelter Corporation provides housing assistance and supportive services to homeless individuals and families. Their programs include emergency shelters, transitional living, and supportive housing for those working towards self-sufficiency.",
                "Spanish":"Mercy Housing and Shelter Corporation brinda asistencia de vivienda y servicios de apoyo a personas y familias sin hogar. Sus programas incluyen refugios de emergencia, viviendas de transición y viviendas de apoyo para quienes trabajan por la autosuficiencia.",
                "Italian":"Mercy Housing and Shelter Corporation fornisce assistenza abitativa e servizi di supporto a persone e famiglie senzatetto. I loro programmi includono rifugi di emergenza, vita transitoria e alloggi di sostegno per coloro che lavorano verso l’autosufficienza.",
                "Polish":"Mercy Housing and Shelter Corporation zapewnia pomoc mieszkaniową i usługi wspierające osobom i rodzinom bezdomnym. Ich programy obejmują schroniska ratunkowe, życie przejściowe i mieszkania wspierające dla osób pracujących na rzecz samowystarczalności."
            },
            url:"https://www.mercyhousingct.org/"
        },
        {
            title:{
                "English":"The Connection, Inc.",
                "Spanish":"La conexión, Inc.",
                "Italian":"La connessione, Inc.",
                "Polish":"Połączenie spółka z ograniczoną odpowiedzialnością"
            },
            description:{
                "English":"The Connection offers a range of housing services, including emergency shelters, transitional housing, and permanent supportive housing for individuals and families experiencing homelessness. They focus on providing comprehensive support to help clients achieve stability.",
                "Spanish":"The Connection ofrece una variedad de servicios de vivienda, incluidos refugios de emergencia, viviendas de transición y viviendas de apoyo permanente para personas y familias sin hogar. Se centran en brindar apoyo integral para ayudar a los clientes a lograr estabilidad.",
                "Italian":"The Connection offre una gamma di servizi abitativi, tra cui alloggi di emergenza, alloggi transitori e alloggi di supporto permanenti per individui e famiglie senzatetto. Si concentrano sulla fornitura di un supporto completo per aiutare i clienti a raggiungere la stabilità.",
                "Polish":"The Connection oferuje szereg usług mieszkaniowych, w tym schroniska ratunkowe, mieszkania przejściowe i stałe mieszkania wspierające dla osób i rodzin doświadczających bezdomności. Koncentrują się na zapewnieniu kompleksowego wsparcia, aby pomóc klientom osiągnąć stabilność."
            },
            url:"https://www.theconnectioninc.org/"
        },
        {
            title:{
                "English":"Columbus House",
                "Spanish":"Casa Colón",
                "Italian":"Casa Colombo",
                "Polish":"Dom Kolumba"
            },
            description:{
                "English":"Columbus House works to end homelessness by providing shelter, housing, and services to individuals and families. Their programs include emergency shelter, medical respite, and permanent supportive housing.",
                "Spanish":"Columbus House trabaja para acabar con la falta de vivienda proporcionando refugio, vivienda y servicios a personas y familias. Sus programas incluyen refugio de emergencia, respiro médico y vivienda de apoyo permanente.",
                "Italian":"Columbus House lavora per porre fine ai senzatetto fornendo riparo, alloggi e servizi a individui e famiglie. I loro programmi includono alloggi di emergenza, assistenza medica e alloggi di supporto permanenti.",
                "Polish":"Columbus House stara się położyć kres bezdomności, zapewniając schronienie, mieszkania i usługi osobom i rodzinom. Ich programy obejmują schronienie w nagłych wypadkach, opiekę medyczną i stałe mieszkania wspierające."
            },
            url:"https://www.columbushouse.org/"
        },
        {
            title:{
                "English":"Open Hearth Association",
                "Spanish":"Asociación Hogar Abierto",
                "Italian":"Associazione Focolare Aperto",
                "Polish":"Stowarzyszenie Otwartego Ogniska"
            },
            description:{
                "English":"The Open Hearth Association provides shelter and support services to men experiencing homelessness. Their programs include emergency shelter, transitional living, and assistance with finding permanent housing and employment.",
                "Spanish":"La Open Hearth Association brinda refugio y servicios de apoyo a hombres sin hogar. Sus programas incluyen refugio de emergencia, vida de transición y asistencia para encontrar vivienda y empleo permanentes.",
                "Italian":"La Open Hearth Association fornisce rifugio e servizi di supporto agli uomini che vivono senzatetto. I loro programmi includono alloggi di emergenza, vita transitoria e assistenza nella ricerca di alloggi e posti di lavoro permanenti.",
                "Polish":"Stowarzyszenie Open Hearth zapewnia schronienie i wsparcie mężczyznom doświadczającym bezdomności. Ich programy obejmują schronienie w nagłych wypadkach, życie przejściowe oraz pomoc w znalezieniu stałego mieszkania i zatrudnienia."
            },
            url:"https://www.theopenhearth.org/"
        },
        {
            title:{
                "English":"New Reach",
                "Spanish":"Nuevo alcance",
                "Italian":"Nuova portata",
                "Polish":"Nowy zasięg"
            },
            description:{
                "English":"New Reach offers emergency shelter, supportive housing, and rapid rehousing services to families and individuals facing homelessness. They aim to provide safe and stable housing while offering the resources needed for long-term success.",
                "Spanish":"New Reach ofrece refugio de emergencia, viviendas de apoyo y servicios rápidos de realojamiento a familias e individuos que se enfrentan a la falta de hogar. Su objetivo es proporcionar viviendas seguras y estables y, al mismo tiempo, ofrecer los recursos necesarios para el éxito a largo plazo.",
                "Italian":"New Reach offre alloggi di emergenza, alloggi di supporto e servizi di ricollocazione rapida a famiglie e individui che si trovano ad affrontare i senzatetto. Mirano a fornire alloggi sicuri e stabili offrendo al contempo le risorse necessarie per il successo a lungo termine.",
                "Polish":"New Reach oferuje schronisko w nagłych przypadkach, mieszkania wspomagające i usługi szybkiego ponownego zamieszkania rodzinom i osobom borykającym się z bezdomnością. Ich celem jest zapewnienie bezpiecznych i stabilnych mieszkań, oferując jednocześnie zasoby potrzebne do osiągnięcia długoterminowego sukcesu."
            },
            url:"https://newreach.org/"
        },
        {
            title:{
                "English":"South Park Inn",
                "Spanish":"South Park Inn",
                "Italian":"South Park Inn",
                "Polish":"South Park Inn"
            },
            description:{
                "English":"South Park Inn offers emergency shelter and transitional housing services to men, women, and children experiencing homelessness. Their programs focus on helping clients find permanent housing and access the support services they need.",
                "Spanish":"South Park Inn ofrece refugio de emergencia y servicios de vivienda de transición a hombres, mujeres y niños sin hogar. Sus programas se centran en ayudar a los clientes a encontrar vivienda permanente y acceder a los servicios de apoyo que necesitan.",
                "Italian":"South Park Inn offre alloggi di emergenza e servizi abitativi temporanei a uomini, donne e bambini senzatetto. I loro programmi si concentrano sull’aiutare i clienti a trovare un alloggio permanente e ad accedere ai servizi di supporto di cui hanno bisogno.",
                "Polish":"South Park Inn oferuje schronisko w nagłych przypadkach i usługi mieszkań przejściowych mężczyznom, kobietom i dzieciom doświadczającym bezdomności. Ich programy koncentrują się na pomaganiu klientom w znalezieniu stałego mieszkania i dostępie do potrzebnych im usług wsparcia."
            },
            url:"https://www.southparkinn.org/"
        },
        {
            title:{
                "English":"House of Bread",
                "Spanish":"House of Bread",
                "Italian":"House of Bread",
                "Polish":"House of Bread"
            },
            description:{
                "English":"House of Bread provides emergency shelter and supportive services to families and individuals experiencing homelessness. Their comprehensive programs are designed to help clients achieve self-sufficiency and stable housing.",
                "Spanish":"House of Bread ofrece refugio de emergencia y servicios de apoyo a familias e individuos sin hogar. Sus programas integrales están diseñados para ayudar a los clientes a lograr la autosuficiencia y una vivienda estable.",
                "Italian":"House of Bread fornisce alloggi di emergenza e servizi di supporto a famiglie e individui che vivono senzatetto. I loro programmi completi sono progettati per aiutare i clienti a raggiungere l’autosufficienza e un alloggio stabile.",
                "Polish":"House of Bread zapewnia schronienie i usługi wsparcia rodzinom i osobom doświadczającym bezdomności. Ich kompleksowe programy mają na celu pomóc klientom osiągnąć samowystarczalność i stabilne mieszkanie."
            },
            url:"https://www.hobread.org/"
        }
    ]

  return (
    <div>
        <br></br>
        <br></br>
        <div className='center'>
            <motion.div
                      initial={{ scale: 0 }}
                      animate={{ rotate: 360, scale: 1.5 }}
                      transition={{
                        type: "spring",
                        stiffness: 240,
                        damping: 40,
                        duration:2
                      }}
            >
                <NightShelterIcon sx={{fontSize:"48px"}} />
            </motion.div>
        </div>
        <br></br>
        <br></br>
        <motion.div
            initial={{ scale: 0, opacity:0 }}
            animate={{ scale: 1, opacity:1 }}
            transition={{
            type: "spring",
            stiffness: 120,
            damping: 10,
            duration:.5
            }}
        >
        <p className='title'>{titleText[state.language]}</p>
        <p className='desc'>{subTitleText[state.language]}</p>
        </motion.div>
        <br></br>
        <Breaker/>
        
        <div className='shelter-card-frame'>
        {
            programs.map((p)=>{
                return (
                    <motion.div
                    initial={{ scale: 0, opacity:0 }}
                    animate={{ scale: 1, opacity:1 }}
                    transition={{
                    type: "spring",
                    stiffness: 350,
                    damping: 50,
                    duration:.9
                    }}
                    >
                        <div style={{display:'flex', justifyContent:'center'}}>
                    <div className='shelter-program-card'>
                        <p className='shelter-program-title'>{p.title[state.language]}</p>
                        <div className='breaker'></div>
                        <p className='shelter-program-desc'>{p.description[state.language]}</p>
                        <br></br>
                        <Button target="_blank" href={p.url} variant='contained'>{visitText[state.language]}</Button>
                        <br></br>
                        <br></br>
                    </div>
                        </div>


                    </motion.div>
                )
            })
        }
        </div>

    </div>
  )
}
export default Shelter