import React from 'react'
import useData from '../../hooks/use'
import { motion, useScroll, useSpring } from "framer-motion";
import SchoolIcon from '@mui/icons-material/School';
import { Button } from '@mui/material';
import './Education.css'
import Breaker from '../../components/Breaker/Breaker';

function Education() {

    const {state,actions} = useData()

    const visitText = {
        "English":"Visit Website",
        "Spanish":"VISITA EL SITIO WEB",
        "Italian":"VISITA IL SITO WEB",
        "Polish":"ODWIEDŹ STRONĘ"
    }

    const titleText = {
        "English":"Education",
        "Spanish":"Educación",
        "Italian":"Formazione scolastica",
        "Polish":"Edukacja"
    }
    const subTitleText = {
        English:"These education services provide valuable resources and programs to help individuals and families improve their skills and achieve their educational and career objectives.",
        Spanish:"Estos servicios educativos brindan recursos y programas valiosos para ayudar a las personas y las familias a mejorar sus habilidades y lograr sus objetivos educativos y profesionales.",
        Italian:"Questi servizi educativi forniscono risorse e programmi preziosi per aiutare gli individui e le famiglie a migliorare le proprie competenze e a raggiungere i propri obiettivi educativi e professionali.",
        Polish:"Te usługi edukacyjne zapewniają cenne zasoby i programy, które pomagają jednostkom i rodzinom w doskonaleniu umiejętności oraz osiąganiu celów edukacyjnych i zawodowych."
    }

    const programs = [
        {
            title:{
                "English":"New Haven Reads",
                "Spanish":"New Haven Reads",
                "Italian":"New Haven Reads",
                "Polish":"New Haven Reads"
            },
            description:{
                "English":"New Haven Reads offers free literacy tutoring and educational support for children in the New Haven area. Their programs include one-on-one tutoring, book bank services, and enrichment programs aimed at improving children's reading skills and fostering a love of learning.",
                "Spanish":"New Haven Reads ofrece tutoría de alfabetización gratuita y apoyo educativo para niños en el área de New Haven. Sus programas incluyen tutoría individual, servicios de banco de libros y programas de enriquecimiento destinados a mejorar las habilidades de lectura de los niños y fomentar el amor por el aprendizaje.",
                "Italian":"New Haven Reads offre tutoraggio gratuito di alfabetizzazione e supporto educativo per i bambini nell'area di New Haven. I loro programmi includono tutoraggio individuale, servizi di banca del libro e programmi di arricchimento volti a migliorare le capacità di lettura dei bambini e promuovere l'amore per l'apprendimento.",
                "Polish":"New Haven Reads oferuje bezpłatne korepetycje z zakresu umiejętności czytania i pisania oraz wsparcie edukacyjne dla dzieci z obszaru New Haven. Ich programy obejmują korepetycje indywidualne, usługi banku książek oraz programy wzbogacające mające na celu poprawę umiejętności czytania u dzieci i rozwijanie zamiłowania do nauki."
            },
            url:"https://newhavenreads.org/"
        },
        {
            title:{
                "English":"Hartford Public Schools Family Resource Centers",
                "Spanish":"Hartford Public Schools Family Resource Centers",
                "Italian":"Hartford Public Schools Family Resource Centers",
                "Polish":"Hartford Public Schools Family Resource Centers"
            },
            description:{
                "English":"Hartford Public Schools Family Resource Centers provide a variety of free educational programs and services for children and their families. These centers offer early childhood education, after-school programs, parent workshops, and family literacy programs to support children's academic success.",
                "Spanish":"Los Centros de Recursos Familiares de las Escuelas Públicas de Hartford brindan una variedad de programas y servicios educativos gratuitos para los niños y sus familias. Estos centros ofrecen educación infantil temprana, programas extraescolares, talleres para padres y programas de alfabetización familiar para apoyar el éxito académico de los niños.",
                "Italian":"I centri di risorse per la famiglia delle scuole pubbliche di Hartford forniscono una varietà di programmi e servizi educativi gratuiti per i bambini e le loro famiglie. Questi centri offrono educazione della prima infanzia, programmi di doposcuola, laboratori per genitori e programmi di alfabetizzazione familiare per sostenere il successo accademico dei bambini.",
                "Polish":"Centra zasobów rodzinnych szkół publicznych w Hartford zapewniają różnorodne bezpłatne programy i usługi edukacyjne dla dzieci i ich rodzin. Ośrodki te oferują wczesną edukację, programy pozalekcyjne, warsztaty dla rodziców i rodzinne programy alfabetyzacji, aby wspierać sukcesy szkolne dzieci."
            },
            url:"https://www.hartfordschools.org/page/welcome-center"
        },
        {
            title:{
                "English":"Adult Education Center - Bridgeport Public Schools",
                "Spanish":"Centro de educación para adultos - Escuelas públicas de Bridgeport",
                "Italian":"Centro per l'educazione degli adulti - Scuole pubbliche di Bridgeport",
                "Polish":"Centrum Edukacji Dorosłych – Szkoły Publiczne w Bridgeport"
            },
            description:{
                "English":"The Adult Education Center in Bridgeport offers free educational programs for adults, including GED preparation, ESL classes, and job training courses. Their mission is to help adult learners improve their skills and achieve their educational and career goals.",
                "Spanish":"El Centro de Educación para Adultos en Bridgeport ofrece programas educativos gratuitos para adultos, que incluyen preparación para el GED, clases de ESL y cursos de capacitación laboral. Su misión es ayudar a los estudiantes adultos a mejorar sus habilidades y alcanzar sus objetivos educativos y profesionales.",
                "Italian":"Il Centro di formazione per adulti di Bridgeport offre programmi educativi gratuiti per adulti, tra cui preparazione GED, lezioni ESL e corsi di formazione professionale. La loro missione è aiutare gli studenti adulti a migliorare le proprie competenze e a raggiungere i propri obiettivi formativi e professionali.",
                "Polish":"Centrum Edukacji Dorosłych w Bridgeport oferuje bezpłatne programy edukacyjne dla dorosłych, w tym przygotowanie do egzaminu GED, zajęcia z języka ESL i kursy przygotowawcze do pracy. Ich misją jest pomaganie dorosłym uczniom w doskonaleniu umiejętności oraz osiąganiu celów edukacyjnych i zawodowych."
            },
            url:"https://www.bridgeportedu.net/academics/adult-education/adult-education"
        },
        {
            title:{
                "English":"Wallingford Adult Education",
                "Spanish":"Educación para adultos en Wallingford",
                "Italian":"Educazione degli adulti a Wallingford",
                "Polish":"Edukacja dorosłych w Wallingford"
            },
            description:{
                "English":"Wallingford Adult Education provides free educational services to adults, including high school diploma programs, GED preparation, and ESL classes. They aim to help adults gain the skills and knowledge needed to succeed in the workforce and in their personal lives.",
                "Spanish":"Wallingford Adult Education brinda servicios educativos gratuitos para adultos, incluidos programas de diploma de escuela secundaria, preparación para el GED y clases de ESL. Su objetivo es ayudar a los adultos a adquirir las habilidades y conocimientos necesarios para tener éxito en el mundo laboral y en su vida personal.",
                "Italian":"Wallingford Adult Education fornisce servizi educativi gratuiti agli adulti, inclusi programmi di diploma di scuola superiore, preparazione GED e lezioni ESL. Il loro obiettivo è aiutare gli adulti ad acquisire le competenze e le conoscenze necessarie per avere successo nel mondo del lavoro e nella vita personale.",
                "Polish":"Wallingford Adult Education zapewnia bezpłatne usługi edukacyjne dla dorosłych, w tym programy do dyplomów szkół średnich, przygotowania do GED i zajęcia z języka ESL. Ich celem jest pomoc dorosłym w zdobywaniu umiejętności i wiedzy potrzebnych do osiągnięcia sukcesu na rynku pracy i w życiu osobistym."
            },
            url:"https://www.wallingfordadulted.org/"
        },
        {
            title:{
                "English":"Connecticut Children's Medical Center - Child Health and Development Institute (CHDI)",
                "Spanish":"Centro médico infantil de Connecticut - Instituto de desarrollo y salud infantil (CHDI)",
                "Italian":"Connecticut Children's Medical Center - Istituto per la salute e lo sviluppo infantile (CHDI)",
                "Polish":"Centrum Medyczne dla Dzieci w Connecticut — Instytut Zdrowia i Rozwoju Dziecka (CHDI)"
            },
            description:{
                "English":"The CHDI provides educational programs and resources focused on improving the health and development of children. Their programs include early childhood education, school readiness initiatives, and developmental screenings to support children's growth and learning.",
                "Spanish":"El CHDI ofrece programas y recursos educativos enfocados en mejorar la salud y el desarrollo de los niños. Sus programas incluyen educación infantil temprana, iniciativas de preparación escolar y evaluaciones del desarrollo para apoyar el crecimiento y el aprendizaje de los niños.",
                "Italian":"Il CHDI fornisce programmi educativi e risorse incentrati sul miglioramento della salute e dello sviluppo dei bambini. I loro programmi includono l'educazione della prima infanzia, iniziative di preparazione scolastica e screening dello sviluppo per sostenere la crescita e l'apprendimento dei bambini.",
                "Polish":"CHDI zapewnia programy i zasoby edukacyjne ukierunkowane na poprawę zdrowia i rozwoju dzieci. Ich programy obejmują wczesną edukację, inicjatywy w zakresie gotowości szkolnej i badania rozwojowe mające na celu wspieranie rozwoju i uczenia się dzieci."
            },
            url:"https://www.chdi.org/"
        }
    ]

  return (
    <div>

        <br></br>
        <br></br>
        <div className='center'>
            <motion.div
                      initial={{ scale: 0 }}
                      animate={{ rotate: 360, scale: 1.5 }}
                      transition={{
                        type: "spring",
                        stiffness: 240,
                        damping: 40,
                        duration:2
                      }}
            >
                <SchoolIcon sx={{fontSize:"48px"}} />
            </motion.div>
        </div>
        <br></br>
        <br></br>
        <motion.div
            initial={{ scale: 0, opacity:0 }}
            animate={{ scale: 1, opacity:1 }}
            transition={{
            type: "spring",
            stiffness: 120,
            damping: 10,
            duration:.5
            }}
        >
        <p className='title'>{titleText[state.language]}</p>
        <p className='desc'>{subTitleText[state.language]}</p>
        </motion.div>
        <br></br>
        <Breaker/>



        <div className='education-card-frame'>
        {
            programs.map((p)=>{
                return (
                    <motion.div
                    initial={{ scale: 0, opacity:0 }}
                    animate={{ scale: 1, opacity:1 }}
                    transition={{
                    type: "spring",
                    stiffness: 350,
                    damping: 50,
                    duration:.9
                    }}
                    >
                        <div style={{display:'flex', justifyContent:'center'}}>
                    <div className='education-program-card'>
                        <p className='education-program-title'>{p.title[state.language]}</p>
                        <div className='breaker'></div>
                        <p className='education-program-desc'>{p.description[state.language]}</p>
                        <br></br>
                        <Button target="_blank" href={p.url} variant='contained'>{visitText[state.language]}</Button>
                        <br></br>
                        <br></br>
                    </div>
                        </div>


                    </motion.div>
                )
            })
        }
        </div>

    </div>
  )
}

export default Education