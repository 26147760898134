import React from 'react'
import './OpeningScroll.css'


function OpeningScroll() {
  return (
    <div>
        <div className="opening-scroll-content">
        <div className='center'>
        <div className="opening-scroll-content-container">
            <ul className="opening-scroll-content-container-list">
                <li className="opening-scroll-content-container-list-item">Hello!</li>
                <li className="opening-scroll-content-container-list-item">Hola!</li>
                <li className="opening-scroll-content-container-list-item">Ciao!</li>
                <li className="opening-scroll-content-container-list-item">Witam!</li>
            </ul>
        </div>
        </div>
        </div>
    </div>
  )
}

export default OpeningScroll