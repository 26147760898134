import React from 'react'
import useData from '../../hooks/use'
import WorkIcon from '@mui/icons-material/Work';
import { motion, useScroll, useSpring } from "framer-motion";
import { Button } from '@mui/material';
import './Employment.css'
import Breaker from '../../components/Breaker/Breaker';

function Employment() {

    const {state,actions} = useData()

    const visitText = {
        "English":"Visit Website",
        "Spanish":"VISITA EL SITIO WEB",
        "Italian":"VISITA IL SITO WEB",
        "Polish":"ODWIEDŹ STRONĘ"
    }

    const titleText = {
        "English":"Employment",
        "Spanish":"Empleo",
        "Italian":"Occupazione",
        "Polish":"Zatrudnienie"
    }
    const subTitleText = {
        English:"These services provide valuable resources and support to help individuals find employment and improve their job skills, contributing to their economic stability and career growth.",
        Spanish:"Estos servicios brindan valiosos recursos y apoyo para ayudar a las personas a encontrar empleo y mejorar sus habilidades laborales, contribuyendo a su estabilidad económica y crecimiento profesional.",
        Italian:"Questi servizi forniscono risorse e supporto preziosi per aiutare le persone a trovare lavoro e a migliorare le proprie competenze lavorative, contribuendo alla loro stabilità economica e alla crescita della carriera.",
        Polish:"Usługi te zapewniają cenne zasoby i wsparcie, które pomagają poszczególnym osobom w znalezieniu zatrudnienia i doskonaleniu umiejętności zawodowych, przyczyniając się do ich stabilności ekonomicznej i rozwoju kariery."
    }

    const programs = [
        {
            title:{
                "English":"American Job Centers",
                "Spanish":"Centros de empleo americanos",
                "Italian":"Centri per l'impiego americani",
                "Polish":"Amerykańskie Centra Pracy"
            },
            description:{
                "English":"American Job Centers offer a wide range of employment services, including job search assistance, resume writing workshops, career counseling, and job training programs. They are dedicated to helping individuals find meaningful employment and improve their job skills.",
                "Spanish":"Los American Job Centers ofrecen una amplia gama de servicios de empleo, que incluyen asistencia en la búsqueda de empleo, talleres de redacción de currículums, asesoramiento profesional y programas de capacitación laboral. Se dedican a ayudar a las personas a encontrar un empleo significativo y mejorar sus habilidades laborales.",
                "Italian":"Gli American Job Centers offrono un'ampia gamma di servizi per l'impiego, tra cui assistenza nella ricerca di lavoro, seminari di scrittura di curriculum, consulenza professionale e programmi di formazione professionale. Si impegnano ad aiutare le persone a trovare un impiego significativo e a migliorare le proprie capacità lavorative.",
                "Polish":"Amerykańskie Centra Pracy oferują szeroką gamę usług związanych z zatrudnieniem, w tym pomoc w poszukiwaniu pracy, warsztaty pisania CV, doradztwo zawodowe i programy szkoleń zawodowych. Ich celem jest pomaganie indywidualnym osobom w znalezieniu sensownego zatrudnienia i doskonaleniu ich umiejętności zawodowych."
            },
            url:"https://portal.ct.gov/dol/divisions/american-job-centers"
        },
        {
            title:{
                "English":"Connecticut Department of Labor (CTDOL)",
                "Spanish":"Departamento de Trabajo de Connecticut (CTDOL)",
                "Italian":"Dipartimento del lavoro del Connecticut (CTDOL)",
                "Polish":"Departament Pracy Connecticut (CTDOL)"
            },
            description:{
                "English":"The Connecticut Department of Labor provides resources and services to job seekers, including job listings, training programs, career fairs, and unemployment assistance. They aim to connect job seekers with employment opportunities and support their career development.",
                "Spanish":"El Departamento de Trabajo de Connecticut brinda recursos y servicios a quienes buscan empleo, incluidos listados de trabajo, programas de capacitación, ferias profesionales y asistencia por desempleo. Su objetivo es conectar a quienes buscan empleo con oportunidades laborales y apoyar su desarrollo profesional.",
                "Italian":"Il Dipartimento del lavoro del Connecticut fornisce risorse e servizi a chi cerca lavoro, inclusi annunci di lavoro, programmi di formazione, fiere del lavoro e assistenza alla disoccupazione. Il loro obiettivo è quello di mettere in contatto le persone in cerca di lavoro con opportunità di lavoro e sostenere il loro sviluppo di carriera.",
                "Polish":"Departament Pracy Connecticut zapewnia zasoby i usługi osobom poszukującym pracy, w tym oferty pracy, programy szkoleniowe, targi kariery i pomoc dla bezrobotnych. Ich celem jest zapewnienie osobom poszukującym pracy możliwości zatrudnienia i wspieranie ich rozwoju zawodowego."
            },
            url:"https://portal.ct.gov/dol"
        },
        {
            title:{
                "English":"The WorkPlace",
                "Spanish":"The WorkPlace",
                "Italian":"The WorkPlace",
                "Polish":"The WorkPlace"
            },
            description:{
                "English":"The WorkPlace offers various programs to help job seekers find employment, including job training, career counseling, and support services. They operate several initiatives, such as the American Job Center, Platform to Employment, and YouthWorks, to assist individuals in achieving their career goals.",
                "Spanish":"WorkPlace ofrece varios programas para ayudar a quienes buscan empleo a encontrar empleo, incluida capacitación laboral, asesoramiento profesional y servicios de apoyo. Operan varias iniciativas, como American Job Center, Platform to Empleo y YouthWorks, para ayudar a las personas a lograr sus objetivos profesionales.",
                "Italian":"WorkPlace offre vari programmi per aiutare le persone in cerca di lavoro a trovare lavoro, tra cui formazione professionale, consulenza professionale e servizi di supporto. Gestiscono diverse iniziative, come American Job Center, Platform to Employment e YouthWorks, per aiutare le persone a raggiungere i propri obiettivi di carriera.",
                "Polish":"WorkPlace oferuje różne programy pomagające osobom poszukującym pracy w znalezieniu zatrudnienia, w tym szkolenia zawodowe, doradztwo zawodowe i usługi wsparcia. Prowadzą kilka inicjatyw, takich jak American Job Centre, Platform to Zatrudnienie i YouthWorks, aby pomóc jednostkom w osiągnięciu celów zawodowych."
            },
            url:"https://www.workplace.org/"
        },
        {
            title:{
                "English":"New Haven Works",
                "Spanish":"New Haven Works",
                "Italian":"New Haven Works",
                "Polish":"New Haven Works"
            },
            description:{
                "English":"New Haven Works connects job seekers with employment opportunities in the New Haven area. They offer job matching services, career counseling, skills training, and support to help individuals find and retain meaningful employment.",
                "Spanish":"New Haven Works conecta a quienes buscan empleo con oportunidades laborales en el área de New Haven. Ofrecen servicios de búsqueda de empleo, asesoramiento profesional, capacitación y apoyo para ayudar a las personas a encontrar y retener un empleo significativo.",
                "Italian":"New Haven Works mette in contatto le persone in cerca di lavoro con opportunità di lavoro nell'area di New Haven. Offrono servizi di abbinamento tra lavoro, consulenza professionale, formazione professionale e supporto per aiutare le persone a trovare e mantenere un impiego significativo.",
                "Polish":"New Haven Works łączy osoby poszukujące pracy z możliwościami zatrudnienia na obszarze New Haven. Oferują usługi w zakresie pośrednictwa pracy, doradztwo zawodowe, szkolenia podnoszące umiejętności oraz wsparcie pomagające jednostkom w znalezieniu i utrzymaniu sensownego zatrudnienia."
            },
            url:"https://newhavenworkspipeline.org/"
        }
    ]

  return (
    <div>
        <br></br>
        <br></br>
        <div className='center'>
            <motion.div
                      initial={{ scale: 0 }}
                      animate={{ rotate: 360, scale: 1.5 }}
                      transition={{
                        type: "spring",
                        stiffness: 240,
                        damping: 40,
                        duration:2
                      }}
            >
                <WorkIcon sx={{fontSize:"48px"}} />
            </motion.div>
        </div>
        <br></br>
        <br></br>
        <motion.div
            initial={{ scale: 0, opacity:0 }}
            animate={{ scale: 1, opacity:1 }}
            transition={{
            type: "spring",
            stiffness: 120,
            damping: 10,
            duration:.5
            }}
        >
        <p className='title'>{titleText[state.language]}</p>
        <p className='desc'>{subTitleText[state.language]}</p>
        </motion.div>
        <br></br>
        <Breaker/>
        
        <div className='employment-card-frame'>
        {
            programs.map((p)=>{
                return (
                    <motion.div
                    initial={{ scale: 0, opacity:0 }}
                    animate={{ scale: 1, opacity:1 }}
                    transition={{
                    type: "spring",
                    stiffness: 350,
                    damping: 50,
                    duration:.9
                    }}
                    >
                        <div style={{display:'flex', justifyContent:'center'}}>
                    <div className='employment-program-card'>
                        <p className='employment-program-title'>{p.title[state.language]}</p>
                        <div className='breaker'></div>
                        <p className='employment-program-desc'>{p.description[state.language]}</p>
                        <br></br>
                        <Button target="_blank" href={p.url} variant='contained'>{visitText[state.language]}</Button>
                        <br></br>
                        <br></br>
                    </div>
                        </div>


                    </motion.div>
                )
            })
        }
        </div>

    </div>
  )
}

export default Employment