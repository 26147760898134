import React from "react";
import useData from "../../hooks/use";
import { motion, useScroll, useSpring } from "framer-motion";
import { Button, TextField } from "@mui/material";
import "./Announcements.css";
import Breaker from "../../components/Breaker/Breaker";
import CampaignIcon from "@mui/icons-material/Campaign";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import AttachFileIcon from "@mui/icons-material/AttachFile";

function Announcements() {
  const { state, actions } = useData();

  const announceOptions = {
    English: "Announcements",
    Spanish: "Anuncios",
    Italian: "Annunci",
    Polish: "Ogłoszenia",
  };
  const subTitleText = {
    English: "Coming soon!",
    Spanish: "¡Muy pronto!",
    Italian: "Prossimamente!",
    Polish: "Już wkrótce!",
  };

  const publishText = {
    English: "Share",
    Spanish: "Publicar",
    Italian: "Pubblicare",
    Polish: "Publikować",
  };

  const fileText = {
    English: "Add File",
    Spanish: "Agregar archivo",
    Italian: "Aggiungi file",
    Polish: "Dodaj plik",
  };

  return (
    <div>
      <br></br>
      <br></br>
      <div className="center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 360, scale: 1.5 }}
          transition={{
            type: "spring",
            stiffness: 240,
            damping: 40,
            duration: 2,
          }}
        >
          <CampaignIcon sx={{ fontSize: "48px" }} />
        </motion.div>
      </div>
      <br></br>
      <br></br>
      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 120,
          damping: 10,
          duration: 0.5,
        }}
      >
        <p className="title">{announceOptions[state.language]}</p>
        <p className="desc">{subTitleText[state.language]}</p>
        <Button
          disabled
          sx={{ width: "120px" }}
          size="small"
          variant="outlined"
        >
          View All
        </Button>
      </motion.div>
      <br></br>
      <Breaker />
      <div className="announcement-frame">
        <p className="announcement-entry-text"></p>
        <div>
          <div className="announcement-input-frame">
            <TextField
              variant="filled"
              multiline
              placeholder="Enter your post here"
              minRows={5}
              fullWidth
              //   sx={{ width: "40%" }}
            />
          </div>
          <br></br>
        </div>
        <div className="announcement-button-frame">
          <Button
            sx={{ width: "200px", marginBottom: "10px" }}
            size="small"
            variant="contained"
            startIcon={<ConnectWithoutContactIcon />}
          >
            {publishText[state.language]}
          </Button>
          <Button
            startIcon={<AttachFileIcon />}
            sx={{ width: "200px" }}
            size="small"
            variant="outlined"
          >
            {fileText[state.language]}
          </Button>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default Announcements;
