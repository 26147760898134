import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import "./ChatInput.css";
import { TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ToggleButton from "@mui/material/ToggleButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Chat } from "../../../../type";

type ChatInputProps = {
  chats: Chat[];
  setChats: Dispatch<SetStateAction<Chat[]>>;
};

function ChatInput({ setChats, chats }: ChatInputProps) {
  const [userText, setUserText] = useState("");
  const handleChange = (e: any) => {
    setUserText(e.target.value);
  };

  const handleClick = () => {
    const additive = {
      id: `${Math.random()}`,
      created: "Right now!",
      user_id: "u1",
      user_name: "Maria Lopez",
      value: userText,
      viewed: false,
    };
    setUserText("");
    setChats([...chats, additive]);
  };

  return (
    <div className="chat-input-frame">
      {/* <p></p> */}
      <ToggleButton
        sx={{
          backgroundColor: "#F0F0F0",
          borderRadius: "0px",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          borderBottomStyle: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "black",
          borderStyle: "none",
          position: "relative",
          top: "0px",
          left: "1px",
          height: "49px",
        }}
        value="left"
        aria-label="left aligned"
      >
        <AttachFileIcon />
      </ToggleButton>
      <TextField
        size="small"
        value={userText}
        onChange={handleChange}
        multiline
        variant="filled"
        sx={{ width: "60%" }}
        maxRows={20}
      />

      <Button
        variant="contained"
        onClick={handleClick}
        sx={{
          height: "47px",
          borderRadius: "0px",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
        }}
        startIcon={<SendIcon sx={{ position: "relative", left: "8px" }} />}
      ></Button>
    </div>
  );
}

export default ChatInput;
