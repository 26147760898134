import { useState, useEffect } from "react";
import React from "react";
import AppContext from "./context";
import { useNavigate } from "react-router-dom";

interface ProviderChildren {
  children: React.ReactNode;
}

function AppProvider({ children }: ProviderChildren) {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [language, setLanguage] = useState<
    "English" | "Spanish" | "Italian" | "Polish"
  >("English");
  const [headerOpen, setHeaderOpen] = useState(false);
  const [googleAPI, setGoogleAPI] = useState(
    "AIzaSyBwfQFtwKMKN8bRMedhjcxS_E2Qz_P908k"
  );
  const [textractApiUrl, setTextractApiUrl] = useState(
    "https://cotp6y35zpu77wczljbxsdniv40dhefj.lambda-url.us-east-1.on.aws"
  );
  const [globalHistory, setGlobalHistory] = useState(["/"]);
  const [historyIdx, setHistoryIdx] = useState(0);
  const [authToken, setAuthToken] = useState<null | string>(null);

  const addHistory = (path: string) => {
    let temp = [...globalHistory];
    temp.push(path);
    setGlobalHistory(temp);
    setHistoryIdx(historyIdx + 1);
  };

  const historyChange = (direction: "back" | "forward") => {
    console.log(direction);
    if (direction === "back") {
      if (historyIdx > 0) {
        navigate(globalHistory[historyIdx - 1]);
        setHistoryIdx(historyIdx - 1);
      }
    } else if (direction == "forward") {
      if (historyIdx < globalHistory.length - 1) {
        navigate(globalHistory[historyIdx + 1]);
        setHistoryIdx(historyIdx + 1);
      }
    }
  };

  const value = {
    state: {
      language,
      headerOpen,
      googleAPI,
      textractApiUrl,
      authToken,
      userId,
    },
    actions: {
      setLanguage,
      setHeaderOpen,
      addHistory,
      setTextractApiUrl,
      setGoogleAPI,
      historyChange,
      setAuthToken,
      setUserId,
    },
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppProvider;
