import React from 'react'
import useData from '../../hooks/use'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { motion, useScroll, useSpring } from "framer-motion";
import { Button } from '@mui/material';
import './Health.css'
import Breaker from '../../components/Breaker/Breaker';

function Health() {

    const {state,actions} = useData()

    const visitText = {
        "English":"Visit Website",
        "Spanish":"VISITA EL SITIO WEB",
        "Italian":"VISITA IL SITO WEB",
        "Polish":"ODWIEDŹ STRONĘ"
    }

    const titleText = {
        "English":"Health",
        "Spanish":"Salud",
        "Italian":"Salute",
        "Polish":"Zdrowie"
    }
    const subTitleText = {
        English:"These healthcare services provide essential medical care to those in need, ensuring access to comprehensive and affordable healthcare.",
        Spanish:"Estos servicios de atención médica brindan atención médica esencial a quienes la necesitan, garantizando el acceso a una atención médica integral y asequible.",
        Italian:"Questi servizi sanitari forniscono assistenza medica essenziale a chi ne ha bisogno, garantendo l’accesso a un’assistenza sanitaria completa e conveniente.",
        Polish:"Te usługi opieki zdrowotnej zapewniają niezbędną opiekę medyczną osobom potrzebującym, zapewniając dostęp do kompleksowej i niedrogiej opieki zdrowotnej."
    }

    const programs = [
        {
            title:{
                "English":"Community Health Center, Inc. (CHC)",
                "Spanish":"Centro de salud comunitario, Inc. (CHC)",
                "Italian":"Centro sanitario comunitario, Inc. (CHC)",
                "Polish":"Społeczne Centrum Zdrowia, Inc. (CHC)"
            },
            description:{
                "English":"Community Health Center, Inc. offers comprehensive healthcare services including primary care, dental care, behavioral health, and more. They operate numerous clinics throughout Connecticut, ensuring accessible care for everyone.",
                "Spanish":"Community Health Center, Inc. ofrece servicios de atención médica integrales que incluyen atención primaria, atención dental, salud conductual y más. Operan numerosas clínicas en todo Connecticut, lo que garantiza una atención accesible para todos.",
                "Italian":"Community Health Center, Inc. offre servizi sanitari completi tra cui cure primarie, cure odontoiatriche, salute comportamentale e altro ancora. Gestiscono numerose cliniche in tutto il Connecticut, garantendo cure accessibili a tutti.",
                "Polish":"Community Health Center, Inc. oferuje kompleksowe usługi opieki zdrowotnej, w tym opiekę podstawową, opiekę stomatologiczną, zdrowie behawioralne i inne. Prowadzą liczne kliniki w całym Connecticut, zapewniając dostępną opiekę dla każdego."
            },
            url:"https://www.chc1.com/"
        },
        {
            title:{
                "English":"Charter Oak Health Center",
                "Spanish":"Charter Oak Health Center",
                "Italian":"Charter Oak Health Center",
                "Polish":"Charter Oak Health Center"
            },
            description:{
                "English":"Charter Oak Health Center provides a wide range of medical services including primary care, urgent care, dental, and behavioral health services. They are committed to offering quality healthcare to underserved populations.",
                "Spanish":"Charter Oak Health Center ofrece una amplia gama de servicios médicos que incluyen atención primaria, atención de urgencia, servicios dentales y de salud conductual. Están comprometidos a ofrecer atención médica de calidad a las poblaciones desatendidas.",
                "Italian":"Il Charter Oak Health Center fornisce un'ampia gamma di servizi medici tra cui cure primarie, cure urgenti, servizi di salute dentale e comportamentale. Si impegnano a offrire assistenza sanitaria di qualità alle popolazioni svantaggiate.",
                "Polish":"Centrum Zdrowia Charter Oak zapewnia szeroki zakres usług medycznych, w tym opiekę podstawową, opiekę pilną, usługi stomatologiczne i usługi w zakresie zdrowia behawioralnego. Angażują się w zapewnianie wysokiej jakości opieki zdrowotnej najuboższym grupom społecznym."
            },
            url:"https://www.thecharteroak.org/"
        },
        {
            title:{
                "English":"Fair Haven Community Health Care",
                "Spanish":"Fair Haven Community Health Care",
                "Italian":"Fair Haven Community Health Care",
                "Polish":"Fair Haven Community Health Care"
            },
            description:{
                "English":"Fair Haven Community Health Care offers primary care, dental care, behavioral health, and specialty services. They aim to provide compassionate, comprehensive care to all individuals and families.",
                "Spanish":"Fair Haven Community Health Care ofrece atención primaria, atención dental, salud conductual y servicios especializados. Su objetivo es brindar atención compasiva e integral a todas las personas y familias.",
                "Italian":"Fair Haven Community Health Care offre cure primarie, cure odontoiatriche, salute comportamentale e servizi specialistici. Il loro obiettivo è fornire assistenza compassionevole e completa a tutti gli individui e alle famiglie.",
                "Polish":"Fair Haven Community Health Care oferuje podstawową opiekę zdrowotną, opiekę stomatologiczną, opiekę behawioralną i usługi specjalistyczne. Ich celem jest zapewnienie pełnej współczucia i kompleksowej opieki wszystkim osobom i rodzinom."
            },
            url:"https://www.fhchc.org/"
        },
        {
            title:{
                "English":"Southwest Community Health Center",
                "Spanish":"Southwest Community Health Center",
                "Italian":"Southwest Community Health Center",
                "Polish":"Southwest Community Health Center"
            },
            description:{
                "English":"Southwest Community Health Center provides a range of healthcare services including medical, dental, and behavioral health. They focus on delivering quality and affordable care to the community.",
                "Spanish":"Southwest Community Health Center ofrece una variedad de servicios de atención médica que incluyen salud médica, dental y conductual. Se centran en brindar atención de calidad y asequible a la comunidad.",
                "Italian":"Il Southwest Community Health Center fornisce una gamma di servizi sanitari tra cui salute medica, dentale e comportamentale. Si concentrano sulla fornitura di cure di qualità e a prezzi accessibili alla comunità.",
                "Polish":"Southwest Community Health Centre zapewnia szereg usług opieki zdrowotnej, w tym opiekę medyczną, stomatologiczną i behawioralną. Koncentrują się na zapewnianiu społeczności wysokiej jakości i niedrogiej opieki."
            },
            url:"https://www.swchc.org/"
        },
        {
            title:{
                "English":"Optimus Health Care",
                "Spanish":"Optimus Health Care",
                "Italian":"Optimus Health Care",
                "Polish":"Optimus Health Care"
            },
            description:{
                "English":"Optimus Health Care offers comprehensive healthcare services including medical, dental, behavioral health, and specialty care. They operate multiple locations throughout Connecticut to serve the community's healthcare needs.",
                "Spanish":"Optimus Health Care ofrece servicios de atención médica integrales que incluyen atención médica, dental, de salud conductual y especializada. Operan en múltiples ubicaciones en todo Connecticut para atender las necesidades de atención médica de la comunidad.",
                "Italian":"Optimus Health Care offre servizi sanitari completi che includono cure mediche, dentistiche, comportamentali e specialistiche. Gestiscono più sedi in tutto il Connecticut per soddisfare le esigenze sanitarie della comunità.",
                "Polish":"Optimus Health Care oferuje kompleksowe usługi opieki zdrowotnej, obejmujące opiekę medyczną, stomatologiczną, behawioralną i opiekę specjalistyczną. Działają w wielu lokalizacjach w całym Connecticut, aby zaspokoić potrzeby społeczności w zakresie opieki zdrowotnej."
            },
            url:"https://www.optimushealthcare.org/"
        }
    ]

  return (
    <div>
        <br></br>
        <br></br>
        <div className='center'>
            <motion.div
                      initial={{ scale: 0 }}
                      animate={{ rotate: 360, scale: 1.5 }}
                      transition={{
                        type: "spring",
                        stiffness: 240,
                        damping: 40,
                        duration:2
                      }}
            >
                <HealthAndSafetyIcon sx={{fontSize:"48px"}} />
            </motion.div>
        </div>
        <br></br>
        <br></br>
        <motion.div
            initial={{ scale: 0, opacity:0 }}
            animate={{ scale: 1, opacity:1 }}
            transition={{
            type: "spring",
            stiffness: 120,
            damping: 10,
            duration:.5
            }}
        >
        <p className='title'>{titleText[state.language]}</p>
        <p className='desc'>{subTitleText[state.language]}</p>
        </motion.div>
        <br></br>
        <Breaker/>
        
        <div className='health-card-frame'>
        {
            programs.map((p)=>{
                return (
                    <motion.div
                    initial={{ scale: 0, opacity:0 }}
                    animate={{ scale: 1, opacity:1 }}
                    transition={{
                    type: "spring",
                    stiffness: 350,
                    damping: 50,
                    duration:.9
                    }}
                    >
                        <div style={{display:'flex', justifyContent:'center'}}>
                    <div className='health-program-card'>
                        <p className='health-program-title'>{p.title[state.language]}</p>
                        <div className='breaker'></div>
                        <p className='health-program-desc'>{p.description[state.language]}</p>
                        <br></br>
                        <Button target="_blank" href={p.url} variant='contained'>{visitText[state.language]}</Button>
                        <br></br>
                        <br></br>
                    </div>
                        </div>


                    </motion.div>
                )
            })
        }
        </div>

    </div>
  )
}
export default Health