import React from 'react'
import useData from '../../hooks/use'
import PaidIcon from '@mui/icons-material/Paid';
import { motion, useScroll, useSpring } from "framer-motion";
import { Button } from '@mui/material';
import './Donate.css'
import Breaker from '../../components/Breaker/Breaker';

function Donate() {

    const {state,actions} = useData()

    const visitText = {
        "English":"Visit Website",
        "Spanish":"VISITA EL SITIO WEB",
        "Italian":"VISITA IL SITO WEB",
        "Polish":"ODWIEDŹ STRONĘ"
    }

    const titleText = {
        "English":"Donate",
        "Spanish":"Donar",
        "Italian":"Donare",
        "Polish":"Podarować"
    }
    const subTitleText = {
        English:"There are many ways to donate to the community of Hartford. Make a difference in the community by donating money or time to any of the programs below.",
        Spanish:"Hay muchas maneras de donar a la comunidad de Hartford. Haga una diferencia en la comunidad donando dinero o tiempo a cualquiera de los programas a continuación.",
        Italian:"Esistono molti modi per donare alla comunità di Hartford. Fai la differenza nella comunità donando denaro o tempo a uno dei programmi elencati di seguito.",
        Polish:"Istnieje wiele sposobów przekazywania datków na rzecz społeczności Hartford. Zmień coś na rzecz społeczności, przekazując pieniądze lub czas dowolnemu z poniższych programów."
    }

    const programs = [
        {
            title:{
                "English":"Hartford Area Habitat for Humanity",
                "Spanish":"Hábitat para la Humanidad del Área de Hartford",
                "Italian":"Hartford Area Habitat per l'umanità",
                "Polish":"Siedlisko dla ludzkości w obszarze Hartford"
            },
            description:{
                "English":"This organization helps families in need of decent and affordable housing. You can volunteer your time on construction projects or donate money to support their efforts.",
                "Spanish":"Esta organización ayuda a familias que necesitan una vivienda digna y asequible. Puede ofrecer su tiempo como voluntario en proyectos de construcción o donar dinero para apoyar sus esfuerzos.",
                "Italian":"Questa organizzazione aiuta le famiglie che necessitano di alloggi dignitosi e convenienti. Puoi offrire volontariamente il tuo tempo a progetti di costruzione o donare denaro per sostenere i loro sforzi.",
                "Polish":"Organizacja ta pomaga rodzinom potrzebującym godnego i niedrogiego mieszkania. Możesz poświęcić swój czas na projekty budowlane lub przekazać pieniądze, aby wesprzeć ich wysiłki."
            },
            url:"https://hfhncc.org/"
        },
        {
            title:{
                "English":"Catholic Charities - Archdiocese of Hartford",
                "Spanish":"Caridades Católicas - Arquidiócesis de Hartford",
                "Italian":"Carità cattoliche - Arcidiocesi di Hartford",
                "Polish":"Katolickie Organizacje Charytatywne – Archidiecezja Hartford"
            },
            description:{
                "English":"This program offers a wide range of services to all individuals regardless of race or religion. Their programs include food services, family centers, and immigration services, providing legal aid and support to immigrants.",
                "Spanish":"Este programa ofrece una amplia gama de servicios a todas las personas, independientemente de su raza o religión. Sus programas incluyen servicios de alimentación, centros familiares y servicios de inmigración, brindando asistencia legal y apoyo a los inmigrantes.",
                "Italian":"Questo programma offre una vasta gamma di servizi a tutti gli individui, indipendentemente dalla razza o dalla religione. I loro programmi includono servizi di ristorazione, centri familiari e servizi di immigrazione, fornendo assistenza legale e sostegno agli immigrati.",
                "Polish":"Program ten oferuje szeroki zakres usług wszystkim osobom, bez względu na rasę i religię. Ich programy obejmują usługi gastronomiczne, centra rodzinne i usługi imigracyjne, zapewniając pomoc prawną i wsparcie imigrantom."
            },
            url:"https://www.ccaoh.org/"
        },
        {
            title:{
                "English":"Center for Latino Progress",
                "Spanish":"Centro para el Progreso Latino",
                "Italian":"Centro para el Progreso Latino",
                "Polish":"Centrum Postępu Latynosów"
            },
            description:{
                "English":"Focused on advancing the socioeconomic conditions of the community in general, they provide various services including workforce development and bike programs. They also offer bilingual education and training programs, and support for immigrant integration.",
                "Spanish":"Centrados en mejorar las condiciones socioeconómicas de la comunidad en general, brindan diversos servicios, incluido el desarrollo de la fuerza laboral y programas de bicicletas. También ofrecen programas de educación y formación bilingües y apoyo a la integración de inmigrantes.",
                "Italian":"Concentrati sul miglioramento delle condizioni socioeconomiche della comunità in generale, forniscono vari servizi tra cui lo sviluppo della forza lavoro e programmi ciclistici. Offrono anche programmi di istruzione e formazione bilingue e supporto per l'integrazione degli immigrati.",
                "Polish":"Koncentrując się na ogólnej poprawie warunków społeczno-ekonomicznych społeczności, świadczą różne usługi, w tym rozwój siły roboczej i programy rowerowe. Oferują także dwujęzyczne programy edukacyjne i szkoleniowe oraz wsparcie na rzecz integracji imigrantów."
            },
            url:"https://ctprf.org/"
        },
        {
            title:{
                "English":"Journey Home",
                "Spanish":"Viaje a casa",
                "Italian":"Ritorno a casa",
                "Polish":"Podróż do domu"
            },
            description:{
                "English":"This organization works to end homelessness in the Hartford region. They coordinate services and funding to ensure a solid system of homelessness prevention and response.",
                "Spanish":"Esta organización trabaja para acabar con la falta de vivienda en la región de Hartford. Coordinan servicios y financiación para garantizar un sistema sólido de prevención y respuesta a las personas sin hogar.",
                "Italian":"Questa organizzazione lavora per porre fine ai senzatetto nella regione di Hartford. Coordinano servizi e finanziamenti per garantire un solido sistema di prevenzione e risposta ai senzatetto.",
                "Polish":"Organizacja ta działa na rzecz położenia kresu bezdomności w regionie Hartford. Koordynują usługi i finansowanie, aby zapewnić solidny system zapobiegania bezdomności i reagowania na nią."
            },
            url:"https://journeyhomect.org/"
        },
        {
            title:{
                "English":"Connecticut Institute for Refugees and Immigrants (CIRI)",
                "Spanish":"Instituto de Connecticut para Refugiados e Inmigrantes (CIRI)",
                "Italian":"Istituto del Connecticut per i rifugiati e gli immigrati (CIRI)",
                "Polish":"Instytut Connecticut dla Uchodźców i Imigrantów (CIRI)"
            },
            description:{
                "English":"CIRI provides legal services to immigrants, refugees, and survivors of human trafficking. Donating money or volunteering as a legal aide, teacher, or mentor can make a significant difference.",
                "Spanish":"CIRI brinda servicios legales a inmigrantes, refugiados y sobrevivientes de la trata de personas. Donar dinero o ofrecerse como asistente legal, maestro o mentor puede marcar una diferencia significativa.",
                "Italian":"Il CIRI fornisce servizi legali a immigrati, rifugiati e sopravvissuti alla tratta di esseri umani. Donare denaro o fare volontariato come assistente legale, insegnante o mentore può fare una differenza significativa.",
                "Polish":"CIRI świadczy usługi prawne imigrantom, uchodźcom i osobom, które przeżyły handel ludźmi. Przekazanie pieniędzy lub wolontariat w charakterze doradcy prawnego, nauczyciela lub mentora mogą znacząco zmienić sytuację."
            },
            url:"https://cirict.org/"
        },
        {
            title:{
                "English":"Foodshare",
                "Spanish":"Compartir alimentos",
                "Italian":"Condivisione del cibo",
                "Polish":"Dzielenie się jedzeniem"
            },
            description:{
                "English":"This regional food bank serves Hartford and Tolland counties, working to alleviate hunger by collecting and distributing food donations. Volunteering opportunities include sorting and distributing food.",
                "Spanish":"Este banco de alimentos regional presta servicios a los condados de Hartford y Tolland y trabaja para aliviar el hambre mediante la recolección y distribución de donaciones de alimentos. Las oportunidades de voluntariado incluyen clasificar y distribuir alimentos.",
                "Italian":"Questa banca alimentare regionale serve le contee di Hartford e Tolland e lavora per alleviare la fame raccogliendo e distribuendo donazioni di cibo. Le opportunità di volontariato includono lo smistamento e la distribuzione del cibo.",
                "Polish":"Ten regionalny bank żywności obsługuje hrabstwa Hartford i Tolland, walcząc z głodem poprzez zbieranie i dystrybucję darowizn żywnościowych. Możliwości wolontariatu obejmują sortowanie i dystrybucję żywności."
            },
            url:"https://ctfoodshare.org/"
        },
        {
            title:{
                "English":"The Salvation Army of Hartford",
                "Spanish":"El Ejército de Salvación de Hartford",
                "Italian":"L'Esercito della Salvezza di Hartford",
                "Polish":"Armia Zbawienia z Hartford"
            },
            description:{
                "English":"Known for providing assistance to those in need, including food, shelter, and emergency financial help. Volunteering opportunities are available in their various programs designed to help low-income families and individuals.",
                "Spanish":"Conocido por brindar asistencia a quienes la necesitan, incluida comida, refugio y ayuda financiera de emergencia. Hay oportunidades de voluntariado disponibles en sus diversos programas diseñados para ayudar a familias e individuos de bajos ingresos.",
                "Italian":"Noto per fornire assistenza a chi ne ha bisogno, inclusi cibo, alloggio e aiuto finanziario di emergenza. Opportunità di volontariato sono disponibili nei loro vari programmi progettati per aiutare le famiglie e gli individui a basso reddito.",
                "Polish":"Znany z zapewniania pomocy potrzebującym, w tym żywności, schronienia i awaryjnej pomocy finansowej. Możliwości wolontariatu są dostępne w ramach różnych programów mających na celu pomoc rodzinom i osobom o niskich dochodach."
            },
            url:"https://easternusa.salvationarmy.org/southern-new-england/hartford/"
        }
    ]

  return (
    <div>

        <br></br>
        <br></br>
        <div className='center'>
            <motion.div
                      initial={{ scale: 0 }}
                      animate={{ rotate: 360, scale: 1.5 }}
                      transition={{
                        type: "spring",
                        stiffness: 240,
                        damping: 40,
                        duration:2
                      }}
            >
                <PaidIcon sx={{fontSize:"48px"}} />
            </motion.div>
        </div>
        <br></br>
        <br></br>
        <motion.div
            initial={{ scale: 0, opacity:0 }}
            animate={{ scale: 1, opacity:1 }}
            transition={{
            type: "spring",
            stiffness: 120,
            damping: 10,
            duration:.5
            }}
        >
        <p className='title'>{titleText[state.language]}</p>
        <p className='desc'>{subTitleText[state.language]}</p>
        </motion.div>
        <br></br>
        <Breaker/>



        
        <div className='donate-card-frame'>
        {
            programs.map((p)=>{
                return (
                    <motion.div
                    initial={{ scale: 0, opacity:0 }}
                    animate={{ scale: 1, opacity:1 }}
                    transition={{
                    type: "spring",
                    stiffness: 350,
                    damping: 50,
                    duration:.9
                    }}
                    >
                        <div style={{display:'flex', justifyContent:'center'}}>
                    <div className='program-card'>
                        <p className='program-title'>{p.title[state.language]}</p>
                        <div className='breaker'></div>
                        <p className='program-desc'>{p.description[state.language]}</p>
                        <br></br>
                        <Button target="_blank" href={p.url} variant='contained'>{visitText[state.language]}</Button>
                        <br></br>
                        <br></br>
                    </div>
                        </div>


                    </motion.div>
                )
            })
        }
        </div>

    </div>
  )
}

export default Donate