import React from 'react'
import useData from '../../hooks/use'
import FastfoodIcon from '@mui/icons-material/Fastfood';
import { motion, useScroll, useSpring } from "framer-motion";
import { Button } from '@mui/material';
import './Food.css'
import Breaker from '../../components/Breaker/Breaker';


function Food() {

    const {state,actions} = useData()

    const visitText = {
        "English":"Visit Website",
        "Spanish":"VISITA EL SITIO WEB",
        "Italian":"VISITA IL SITO WEB",
        "Polish":"ODWIEDŹ STRONĘ"
    }

    const titleText = {
        "English":"Food",
        "Spanish":"Comida",
        "Italian":"Cibo",
        "Polish":"Żywność"
    }
    const subTitleText = {
        English:"If you are in need of food, please look at the below programs who will be able to provide you food without pay.",
        Spanish:"Si necesita alimentos, consulte los programas a continuación que podrán proporcionarle alimentos sin pago.",
        Italian:"Se hai bisogno di cibo, consulta i programmi seguenti che saranno in grado di fornirti cibo senza retribuzione.",
        Polish:"Jeśli potrzebujesz żywności, zapoznaj się z poniższymi programami, które będą w stanie zapewnić Ci żywność bez wynagrodzenia."
    }

    const programs = [
        {
            title:{
                "English":"Connecticut Food Bank",
                "Spanish":"Banco de Alimentos de Connecticut",
                "Italian":"Banca alimentare del Connecticut",
                "Polish":"Bank Żywności Connecticut"
            },
            description:{
                "English":"Connecticut Food Bank partners with local food pantries, soup kitchens, and shelters to provide nutritious food to those in need. They distribute millions of meals annually and offer various programs to help alleviate hunger in the community.",
                "Spanish":"Connecticut Food Bank se asocia con despensas de alimentos, comedores comunitarios y refugios locales para proporcionar alimentos nutritivos a quienes los necesitan. Distribuyen millones de comidas anualmente y ofrecen varios programas para ayudar a aliviar el hambre en la comunidad.",
                "Italian":"La Connecticut Food Bank collabora con dispense alimentari locali, mense per i poveri e rifugi per fornire cibo nutriente a chi ne ha bisogno. Distribuiscono milioni di pasti ogni anno e offrono vari programmi per aiutare ad alleviare la fame nella comunità.",
                "Polish":"Connecticut Food Bank współpracuje z lokalnymi spiżarniami żywności, jadłodajniami i schroniskami, aby zapewnić potrzebującym pożywną żywność. Rozdają miliony posiłków rocznie i oferują różne programy pomagające złagodzić głód w społeczności."
            },
            url:"https://www.ctfoodbank.org/"
        },
        {
            title:{
                "English":"Foodshare",
                "Spanish":"Foodshare",
                "Italian":"Foodshare",
                "Polish":"Foodshare"
            },
            description:{
                "English":"Foodshare serves Hartford and Tolland counties, working to end hunger through community partnerships. They offer mobile food pantries, food banks, and various hunger-relief programs to ensure everyone has access to healthy food.",
                "Spanish":"Foodshare presta servicios a los condados de Hartford y Tolland y trabaja para acabar con el hambre a través de asociaciones comunitarias. Ofrecen despensas móviles de alimentos, bancos de alimentos y varios programas de alivio del hambre para garantizar que todos tengan acceso a alimentos saludables.",
                "Italian":"Foodshare serve le contee di Hartford e Tolland, lavorando per porre fine alla fame attraverso partenariati comunitari. Offrono dispense alimentari mobili, banche alimentari e vari programmi di riduzione della fame per garantire a tutti l’accesso a cibo sano.",
                "Polish":"Foodshare obsługuje hrabstwa Hartford i Tolland, pracując nad położeniem kresu głodowi poprzez partnerstwa społeczne. Oferują mobilne spiżarnie żywności, banki żywności i różne programy pomocy głodowej, aby zapewnić każdemu dostęp do zdrowej żywności."
            },
            url:"https://www.foodshare.org/"
        },
        {
            title:{
                "English":"Hands On Hartford",
                "Spanish":"Hands On Hartford",
                "Italian":"Hands On Hartford",
                "Polish":"Hands On Hartford"
            },
            description:{
                "English":"Hands On Hartford provides comprehensive food and nutrition programs, including a community meals program, a food pantry, and mobile food share. They focus on addressing food insecurity and promoting health and wellness.",
                "Spanish":"Hands On Hartford ofrece programas integrales de alimentación y nutrición, incluido un programa de comidas comunitarias, una despensa de alimentos y reparto móvil de alimentos. Se centran en abordar la inseguridad alimentaria y promover la salud y el bienestar.",
                "Italian":"Hands On Hartford fornisce programmi alimentari e nutrizionali completi, tra cui un programma di pasti comunitari, una dispensa alimentare e una condivisione mobile di cibo. Si concentrano sulla lotta all’insicurezza alimentare e sulla promozione della salute e del benessere.",
                "Polish":"Hands On Hartford zapewnia kompleksowe programy żywnościowe i żywieniowe, w tym program posiłków dla społeczności, spiżarnię żywności i mobilny udział w żywności. Koncentrują się na rozwiązaniu problemu braku bezpieczeństwa żywnościowego oraz promowaniu zdrowia i dobrego samopoczucia."
            },
            url:"https://handsonhartford.org/"
        },
        {
            title:{
                "English":"St. Vincent De Paul Middletown",
                "Spanish":"St. Vincent De Paul Middletown",
                "Italian":"St. Vincent De Paul Middletown",
                "Polish":"St. Vincent De Paul Middletown"
            },
            description:{
                "English":"St. Vincent De Paul Middletown operates a community soup kitchen and food pantry, offering hot meals and groceries to individuals and families in need. They also provide supportive services to help clients achieve greater stability.",
                "Spanish":"St. Vincent De Paul Middletown opera un comedor comunitario y una despensa de alimentos, que ofrece comidas calientes y comestibles a personas y familias necesitadas. También brindan servicios de apoyo para ayudar a los clientes a lograr una mayor estabilidad.",
                "Italian":"St. Vincent De Paul Middletown gestisce una mensa comunitaria e una dispensa alimentare, che offre pasti caldi e generi alimentari a individui e famiglie bisognose. Forniscono anche servizi di supporto per aiutare i clienti a raggiungere una maggiore stabilità.",
                "Polish":"St. Vincent De Paul Middletown prowadzi lokalną kuchnię zupową i spiżarnię, oferując gorące posiłki i artykuły spożywcze potrzebującym osobom i rodzinom. Świadczą również usługi wsparcia, aby pomóc klientom osiągnąć większą stabilność."
            },
            url:"https://www.svdmiddletown.org/"
        },
        {
            title:{
                "English":"Downtown Evening Soup Kitchen (DESK)",
                "Spanish":"Downtown Evening Soup Kitchen (DESK))",
                "Italian":"Downtown Evening Soup Kitchen (DESK)",
                "Polish":"Downtown Evening Soup Kitchen (DESK)"
            },
            description:{
                "English":" DESK provides free, nutritious meals to individuals experiencing food insecurity. Their programs include a nightly dinner service, food pantry, and a food delivery program for homebound individuals.",
                "Spanish":"DESK ofrece comidas nutritivas y gratuitas a personas que experimentan inseguridad alimentaria. Sus programas incluyen un servicio de cena nocturno, despensa de alimentos y un programa de entrega de alimentos para personas confinadas en sus hogares.",
                "Italian":"DESK fornisce pasti nutrienti e gratuiti alle persone che soffrono di insicurezza alimentare. I loro programmi includono un servizio di cena serale, una dispensa alimentare e un programma di consegna di cibo per le persone costrette a casa.",
                "Polish":"DESK zapewnia bezpłatne, pożywne posiłki osobom doświadczającym braku bezpieczeństwa żywnościowego. Ich programy obejmują wieczorną kolację, spiżarnię żywności i program dostarczania żywności dla osób nie wracających do domu."
            },
            url:"https://www.downtowneveningsoupkitchen.com/"
        }
    ]

  return (
    <div>
        <br></br>
        <br></br>
        <div className='center'>
            <motion.div
                      initial={{ scale: 0 }}
                      animate={{ rotate: 360, scale: 1.5 }}
                      transition={{
                        type: "spring",
                        stiffness: 240,
                        damping: 40,
                        duration:2
                      }}
            >
                <FastfoodIcon sx={{fontSize:"48px"}} />
            </motion.div>
        </div>
        <br></br>
        <br></br>
        <motion.div
            initial={{ scale: 0, opacity:0 }}
            animate={{ scale: 1, opacity:1 }}
            transition={{
            type: "spring",
            stiffness: 120,
            damping: 10,
            duration:.5
            }}
        >
        <p className='title'>{titleText[state.language]}</p>
        <p className='desc'>{subTitleText[state.language]}</p>
        </motion.div>
        <br></br>
        <Breaker/>
        
        <div className='food-card-frame'>
        {
            programs.map((p)=>{
                return (
                    <motion.div
                    initial={{ scale: 0, opacity:0 }}
                    animate={{ scale: 1, opacity:1 }}
                    transition={{
                    type: "spring",
                    stiffness: 350,
                    damping: 50,
                    duration:.9
                    }}
                    >
                        <div style={{display:'flex', justifyContent:'center'}}>
                    <div className='food-program-card'>
                        <p className='food-program-title'>{p.title[state.language]}</p>
                        <div className='breaker'></div>
                        <p className='food-program-desc'>{p.description[state.language]}</p>
                        <br></br>
                        <Button target="_blank" href={p.url} variant='contained'>{visitText[state.language]}</Button>
                        <br></br>
                        <br></br>
                    </div>
                        </div>


                    </motion.div>
                )
            })
        }
        </div>

    </div>
  )
}
export default Food