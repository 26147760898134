import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Help from "./pages/Help/Help";
import ChatPage from "./pages/Chat/ChatPage";
import Donate from "./pages/Donate/Donate";
import Volunteer from "./pages/Volunteer/Volunteer";
import Shelter from "./pages/Shelter/Shelter";
import Food from "./pages/Food/Food";
import Health from "./pages/Health/Health";
import Education from "./pages/Education/Education";
import Employment from "./pages/Employment/Employment";
import Translate from "./pages/Translate/Translate";
import TextTranslate from "./pages/Translate/sections/TextTranslate/TextTranslate";
import PictureTranslate from "./pages/Translate/sections/PictureTranslate/PictureTranslate";
import Announcements from "./pages/Announcements/Announcements";
import AppProvider from "./provider";
import PrivateRoute from "./PrivateRoute";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";
import { motion, useScroll, useSpring } from "framer-motion";

// Create a theme instance
const theme = createTheme({
  palette: {
    primary: {
      main: "#000066", // Example: Changing to a deep orange color
    },
  },
});

function App() {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppProvider>
          <Header />
          {/* <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/help" element={<Help />} />
            <Route path="/donate" element={<Donate />} />
            <Route path="/volunteer" element={<Volunteer />} />
            <Route path="/shelter" element={<Shelter />} />
            <Route path="/food" element={<Food />} />
            <Route path="/health" element={<Health />} />
            <Route path="/education" element={<Education />} />
            <Route path="/employment" element={<Employment />} />
            <Route path="/translate" element={<Translate />} />
            <Route path="/translate/text" element={<TextTranslate />} />
            <Route path="/translate/photo" element={<PictureTranslate />} />
          </Routes> */}
          <Routes>
            <Route path="/login" element={<Home />} />
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <>
                    <Header />
                    <Routes>
                      <Route path="/" element={<ChatPage />} />
                      <Route path="/help" element={<Help />} />
                      <Route path="/donate" element={<Donate />} />
                      <Route path="/volunteer" element={<Volunteer />} />
                      <Route path="/shelter" element={<Shelter />} />
                      <Route path="/food" element={<Food />} />
                      <Route path="/health" element={<Health />} />
                      <Route path="/education" element={<Education />} />
                      <Route path="/employment" element={<Employment />} />
                      <Route
                        path="/announcements"
                        element={<Announcements />}
                      />
                      {/* <Route path="/translate" element={<Translate />} />
                      <Route
                        path="/translate/text"
                        element={<TextTranslate />}
                      />
                      <Route
                        path="/translate/photo"
                        element={<PictureTranslate />}
                      /> */}
                    </Routes>
                  </>
                </PrivateRoute>
              }
            />
          </Routes>
        </AppProvider>
      </ThemeProvider>
      <motion.div className="help-progress-bar" style={{ scaleX }} />
    </div>
  );
}

export default App;
