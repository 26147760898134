import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useData from "./hooks/use";

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { state } = useData();
  return state.authToken ? <>{children}</> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
